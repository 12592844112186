import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Flex = makeShortcode("Flex");
const Icon = makeShortcode("Icon");
const Button = makeShortcode("Button");
const InfoBlock = makeShortcode("InfoBlock");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Box sx={{
      textAlign: "center"
    }} mdxType="Box">
      <h1>{`Learn About MakerDAO`}</h1>
      <p><img parentName="p" {...{
          "src": "/images/illus_learn.png",
          "alt": "Learn"
        }}></img></p>
    </Box>
    <h2>{`The Future of Finance - All Welcome`}</h2>
    <p>{`Learning about MakerDAO and DeFi is easy, and getting in on the action with MakerDAO is easy. We are here to teach everything, from the basics of wallets to the details of Maker governance. Check out the links below to get started.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '12px',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="link" mdxType="Icon" />
          <p>{`Learn about Blockchain`}</p>
          <Button to="/learn/blockchain-fundamentals/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Blockchain</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="maker" mdxType="Icon" />
          <p>{`Get to know MakerDAO`}</p>
          <Button to="/learn/MakerDAO/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">MakerDAO</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="dai" mdxType="Icon" />
          <p>{`Find out how to get & use Dai, a decentralized stablecoin`}</p>
          <Button to="/learn/Dai/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Dai</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p>{`Learn how to mint new Dai with Maker Vaults`}</p>
          <Button to="/learn/vaults/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Vaults</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="press" mdxType="Icon" />
          <p>{`Find out how to use Maker to participate in MakerDAO governance`}</p>
          <Button to="/learn/governance/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Governance</Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="menu" mdxType="Icon" />
          <p>{`Get informed about off-chain and on-chain price oracles`}</p>
          <Button to="/learn/Oracles/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">Oracles</Button>
        </Flex>
      </Chocolate>
    </Box>
    <InfoBlock mdxType="InfoBlock">
      <Box sx={{
        paddingRight: '35px'
      }} mdxType="Box">
        <h2>{`Learn How to Get Dai and Earn Interest with Maker Vaults`}</h2>
      </Box>
      <Box sx={{
        paddingLeft: '25px'
      }} mdxType="Box">
        <List mdxType="List">
          <Link to="/learn/vaults/vaults-tutorial/" mdxType="Link">Vaults Tutorial</Link>
          <Link to="/learn/vaults/vault-onboarding-guide/" mdxType="Link">Vaults Onboarding Guide</Link>
          <Link to="/learn/vaults/risk/" mdxType="Link">Risk</Link>
        </List>
      </Box>
    </InfoBlock>
    {
      /* <InfoBlock>
      ![Welcome to Comm-dev](/images/illus_home.png)
      <Box>
      ## Learn How to Vote on Changes to MakerDAO
      Vote on changes to the MakerDAO system using the Maker governance token. Learn more about how voting works!
      <Button to="/en/learn/governance/how-voting-works/">How Voting Works</Button>
      </Box>
      </InfoBlock> */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      